*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.aspect-w-9 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 9;
  position: relative;
}

.aspect-w-9 > * {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
}

.aspect-h-16 {
  --tw-aspect-h: 16;
}

.aspect-w-4 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 4;
  position: relative;
}

.aspect-w-4 > * {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
}

.aspect-h-3 {
  --tw-aspect-h: 3;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.top-4 {
  top: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.top-8 {
  top: 2rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[77px\] {
  bottom: 77px;
}

.top-1\/2 {
  top: 50%;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-0 {
  z-index: 0;
}

.z-\[100000\] {
  z-index: 100000;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-12 {
  grid-column-end: 12;
}

.row-start-2 {
  grid-row-start: 2;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-\[calc\(40\%\+\(3\*48px\)\)\] {
  margin-left: calc(40% + 144px);
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-\[32px\] {
  margin-bottom: 32px;
}

.mb-\[42px\] {
  margin-bottom: 42px;
}

.mb-\[28px\] {
  margin-bottom: 28px;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-\[2px\] {
  height: 2px;
}

.h-6 {
  height: 1.5rem;
}

.h-0 {
  height: 0;
}

.h-screen {
  height: 100vh;
}

.h-fit {
  height: fit-content;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[14px\] {
  height: 14px;
}

.w-full {
  width: 100%;
}

.w-0 {
  width: 0;
}

.w-6 {
  width: 1.5rem;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[28px\] {
  width: 28px;
}

.w-\[14px\] {
  width: 14px;
}

.max-w-\[335px\] {
  max-width: 335px;
}

.max-w-\[1920px\] {
  max-width: 1920px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.-translate-y-\[2px\] {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-y-\[3px\] {
  --tw-translate-y: 3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-\[0\.75\] {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-12 {
  gap: 3rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-0 {
  column-gap: 0;
}

.justify-self-end {
  justify-self: end;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-xl {
  border-radius: .75rem;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-ue-custom-dark-gray {
  border-color: var(--ue-custom-dark-gray);
}

.border-\[\#6f6f6f\] {
  --tw-border-opacity: 1;
  border-color: rgb(111 111 111 / var(--tw-border-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.bg-\[\#6f6f6f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(111 111 111 / var(--tw-bg-opacity) );
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.object-cover {
  object-fit: cover;
}

.p-5 {
  padding: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-\[25px\] {
  padding-left: 25px;
  padding-right: 25px;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-\[120px\] {
  padding-top: 120px;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-\[76px\] {
  padding-bottom: 76px;
}

.pt-\[50vh\] {
  padding-top: 50vh;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-\[17\.5px\] {
  padding-top: 17.5px;
}

.pb-\[17\.5px\] {
  padding-bottom: 17.5px;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.\!text-right {
  text-align: right !important;
}

.font-ue-custom-700 {
  font-family: var(--ue-custom-700), Arial, Helvetica, sans-serif;
}

.font-ue-custom-550 {
  font-family: var(--ue-custom-550), Arial, Helvetica, sans-serif;
}

.font-ue-custom-500 {
  font-family: var(--ue-custom-500), Arial, Helvetica, sans-serif;
}

.font-ue-custom-400 {
  font-family: var(--ue-custom-400), Arial, Helvetica, sans-serif;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[64px\] {
  font-size: 64px;
}

.text-ue-h1-mobile {
  font-size: 48px;
  line-height: 56px;
}

.text-ue-body-small-desktop {
  font-size: 14px;
  line-height: 22px;
}

.text-ue-body-small-mobile {
  font-size: 12px;
  line-height: 16px;
}

.text-ue-h2-mobile {
  font-size: 36px;
  line-height: 36px;
}

.text-ue-body-large-mobile {
  font-size: 18px;
  line-height: 26px;
}

.text-ue-body-xs-mobile {
  font-size: 10px;
  line-height: 24px;
}

.text-ue-h3-mobile {
  font-size: 18px;
  line-height: 36px;
}

.text-ue-body-base-desktop {
  font-size: 16px;
  line-height: 24px;
}

.text-ue-body-large-desktop {
  font-size: 20px;
  line-height: 28px;
}

.uppercase {
  text-transform: uppercase;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-\[56px\] {
  line-height: 56px;
}

.tracking-\[-0\.02em\] {
  letter-spacing: -.02em;
}

.tracking-ue-h2-desktop-letter {
  letter-spacing: -.015em;
}

.tracking-ue-body-large-desktop-letter, .tracking-ue-base-desktop-letter {
  letter-spacing: .01em;
}

.tracking-\[-0\.23px\] {
  letter-spacing: -.23px;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-ue-custom-overlaid-gray {
  color: var(--ue-custom-overlaid-gray);
}

.text-ue-custom-mid-gray {
  color: var(--ue-custom-mid-gray);
}

.text-ue-custom-dark-gray {
  color: var(--ue-custom-dark-gray);
}

.text-\[\#c4c4c4\] {
  --tw-text-opacity: 1;
  color: rgb(196 196 196 / var(--tw-text-opacity) );
}

.opacity-0 {
  opacity: 0;
}

.opacity-30 {
  opacity: .3;
}

.opacity-70 {
  opacity: .7;
}

.opacity-100 {
  opacity: 1;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

html {
  scroll-behavior: smooth;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

:root {
  --ue-custom-light-gray: #b3b3b3;
  --ue-custom-mid-gray: #6b6b6e;
  --ue-custom-overlaid-gray: #9e9a97;
  --ue-custom-dark-gray: #0d0e13;
  --ue-white: #fff;
  --ue-black: #000;
  --ue-custom-300: "TWK Lausanne Pan 300";
  --ue-custom-400: "TWK Lausanne Pan 400";
  --ue-custom-500: "TWK Lausanne Pan 500";
  --ue-custom-550: "TWK Lausanne Pan 550";
  --ue-custom-700: "TWK Lausanne Pan 700";
}

* {
  font-family: var(--ue-custom-300), Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: fit-content;
  margin-top: 116px;
}

[data-reveal="fade-in-up"] {
  opacity: 0;
  transform: translateY(20px);
}

.accordion-section .accordion-content {
  transition: all 1s cubic-bezier(.31, .64, .45, .99);
}

.accordion-section .accordion-content ul li:before {
  content: "";
  background-color: var(--ue-custom-light-gray);
  width: 5px;
  height: 5px;
  border-radius: 99px;
  position: absolute;
  top: 10px;
  left: 0;
}

.accordion-section .hover-image-container {
  transition: all .25s ease-in-out;
}

.accordion-section.accordion-open .hover-image-container {
  opacity: 0;
}

.swiper .swiper-slide.transition-ended h2 {
  opacity: 1;
  transition: all .5s ease-in-out 1.25s;
  transform: translateY(-20px);
}

.swiper-button-prev:after, .swiper-button-next:after {
  display: none;
}

.swiper-button-prev, .swiper-button-next {
  top: unset;
  left: unset;
  bottom: unset;
  right: unset;
  text-align: left;
  width: fit-content;
  height: unset;
  margin: 0;
  padding: 0;
  transform: none;
}

.explore-button:after, .explore-button:before {
  content: "";
  height: 60px;
  width: 1px;
  background-color: #fff;
  position: absolute;
  top: 38px;
  left: 50%;
}

.explore-button:after {
  height: 20px;
  animation: pulse 5s infinite;
}

.explore-button:before {
  opacity: .3;
}

@keyframes pulse {
  0% {
    height: 0;
    top: 38px;
    bottom: unset;
  }

  40% {
    height: 60px;
    top: 38px;
    top: bottom;
  }

  55% {
    height: 60px;
    bottom: -76px;
    top: unset;
  }

  85% {
    height: 0;
    bottom: -76px;
    top: unset;
  }

  100% {
    height: 0;
    bottom: -76px;
    top: unset;
  }
}

.swiper-pagination .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  opacity: 1;
  background-color: unset;
  border: 1px solid #fff;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.swiper .swiper-slide img {
  transition: all linear;
}

.swiper-slide-active {
  z-index: 200;
}

.swiper .swiper-slide.transition-ended img {
  transition: all 2s ease-in-out;
  transform: scale(1.1);
}

.swiper .swiper-slide.transition-ended .content-wrapper {
  opacity: 1;
}

.swiper .img-container, .swiper h2 {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.blurred-images-wrapper .overlay, .blurred-image {
  transition: all .5s ease-in-out;
}

.blurred-image.active {
  opacity: 1;
}

.tiles-section .overlay, .tiles-section h3 {
  transition: all .3s ease-in-out;
}

.tiles-section img {
  transition: all .5s ease-out;
}

@media screen and (max-width: 1439px) {
  .tiles-section .tile.active .tile-description, .tiles-section .tile.active .overlay, .tiles-section .tile.active .plus-icon {
    transition: all .3s linear;
  }
}

.tiles-section .tile.active .tile-description {
  opacity: 1;
}

.tiles-section .tile.active .overlay {
  opacity: .5;
}

.tiles-section .tile.active .plus-icon {
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .hover-image-container, .hover-image-wrapper {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .swiper-slide {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (min-width: 1440px) {
  .tiles-section .tile .content-container {
    opacity: 0;
    transition: all .2s linear;
  }

  .tiles-section .tile:hover .content-container {
    opacity: 1;
  }

  .tiles-section .tile:hover .overlay {
    opacity: .5;
  }

  .tiles-section img {
    scale: 1.1;
  }

  .tiles-section .tile:hover img {
    transform: scale(1.15);
  }
}

.main-header.nav-open .burger-icon {
  display: none;
}

a:not(.btn-primary, .carousel-controller, .start-reading):hover {
  opacity: .5;
}

a:not(.btn-primary, .carousel-controller, .start-reading) {
  transition: opacity .2s;
}

.h-fit {
  height: -moz-max-content;
  height: fit-content;
}

.hover\:opacity-50:hover {
  opacity: .5;
}

@media (min-width: 768px) {
  .md\:aspect-w-4 {
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 4;
    position: relative;
  }

  .md\:aspect-w-4 > * {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
  }

  .md\:aspect-h-3 {
    --tw-aspect-h: 3;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:col-end-12 {
    grid-column-end: 12;
  }

  .md\:col-end-9 {
    grid-column-end: 9;
  }

  .md\:col-end-13 {
    grid-column-end: 13;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:\!h-full {
    height: 100% !important;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:pt-\[69px\] {
    padding-top: 69px;
  }

  .md\:pb-\[85px\] {
    padding-bottom: 85px;
  }
}

@media (min-width: 1024px) {
  .lg\:relative {
    position: relative;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:col-end-10 {
    grid-column-end: 10;
  }

  .lg\:col-end-11 {
    grid-column-end: 11;
  }

  .lg\:col-end-8 {
    grid-column-end: 8;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mr-14 {
    margin-right: 3.5rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-\[120px\] {
    height: 120px;
  }

  .lg\:h-\[28px\] {
    height: 28px;
  }

  .lg\:w-\[28px\] {
    width: 28px;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:-translate-x-1 {
    --tw-translate-x: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:translate-y-\[2px\] {
    --tw-translate-y: 2px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:-translate-y-\[13px\] {
    --tw-translate-y: -13px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:translate-y-\[6px\] {
    --tw-translate-y: 6px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:-translate-y-2 {
    --tw-translate-y: -.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:scale-\[1\] {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-7 {
    padding: 1.75rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pt-\[120px\] {
    padding-top: 120px;
  }

  .lg\:pb-\[120px\] {
    padding-bottom: 120px;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .lg\:pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-\[24px\] {
    font-size: 24px;
  }

  .lg\:text-\[95px\] {
    font-size: 95px;
  }

  .lg\:text-ue-h1-tablet {
    font-size: 72px;
    line-height: 80px;
  }

  .lg\:text-ue-body-small-desktop {
    font-size: 14px;
    line-height: 22px;
  }

  .lg\:text-ue-h2-desktop {
    font-size: 49px;
    line-height: 56px;
  }

  .lg\:text-ue-body-large-desktop {
    font-size: 20px;
    line-height: 28px;
  }

  .lg\:text-ue-h3-desktop {
    font-size: 31px;
    line-height: 36px;
  }

  .lg\:text-ue-body-base-desktop {
    font-size: 16px;
    line-height: 24px;
  }

  .lg\:text-ue-body-large-mobile {
    font-size: 18px;
    line-height: 26px;
  }

  .lg\:leading-\[88px\] {
    line-height: 88px;
  }

  .lg\:tracking-ue-base-desktop-letter {
    letter-spacing: .01em;
  }
}

@media (min-width: 1440px) {
  .xl\:aspect-w-16 {
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 16;
    position: relative;
  }

  .xl\:aspect-w-16 > * {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
  }

  .xl\:aspect-h-9 {
    --tw-aspect-h: 9;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:text-ue-h1-desktop {
    font-size: 92px;
    line-height: 88px;
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:opacity-100 {
    opacity: 1;
  }
}

@media (min-width: 1681px) {
  .\32 xl\:text-ue-body-large-desktop {
    font-size: 20px;
    line-height: 28px;
  }
}

/*# sourceMappingURL=index.ddee6cca.css.map */
