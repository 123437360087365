@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
}

:root {
  --ue-custom-light-gray: #b3b3b3;
  --ue-custom-mid-gray: #6b6b6e;
  --ue-custom-overlaid-gray: #9E9A97;
  --ue-custom-dark-gray: #0D0E13;
  --ue-white: #ffffff;
  --ue-black: #000000;
  --ue-custom-300: "TWK Lausanne Pan 300";
  --ue-custom-400: "TWK Lausanne Pan 400";
  --ue-custom-500: "TWK Lausanne Pan 500";
  --ue-custom-550: "TWK Lausanne Pan 550";
  --ue-custom-700: "TWK Lausanne Pan 700";
}


* {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-family: var(--ue-custom-300), Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: fit-content;
  margin-top: 116px;
}

/* 
[data-reveal="expand-up"] {
  -webkit-clip-path: inset(50% 0% 50% 0%);
  clip-path: inset(50% 0% 50% 0%);
}
 */
[data-reveal="fade-in-up"] {
  opacity: 0;
  transform: translateY(20px);
}

.accordion-section .accordion-content {
  transition: 1s cubic-bezier(.31, .64, .45, .99);
}

.accordion-section .accordion-content ul li::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  background-color: var(--ue-custom-light-gray);
  width: 5px;
  height: 5px;
  border-radius: 99px;
}

.accordion-section .hover-image-container {
  transition: all 0.25s ease-in-out;
}

.accordion-section.accordion-open .hover-image-container {
  opacity: 0;
}

.swiper .swiper-slide.transition-ended h2 {
  transition: 0.5s ease-in-out;
  transform: translateY(-20px);
}

.swiper .swiper-slide.transition-ended h2 {
  transition-delay: 1.25s;
  opacity: 1;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  margin: 0;
  padding: 0;
  top: unset;
  left: unset;
  bottom: unset;
  right: unset;
  text-align: left;
  transform: none;
  width: fit-content;
  height: unset;
}

.explore-button::after,
.explore-button::before {
  content: '';
  height: 60px;
  position: absolute;
  width: 1px;
  /* bottom: -76px; */
  top: 38px;
  left: 50%;
  background-color: white;

}

.explore-button::after {
  height: 20px;
  animation: pulse 5s infinite;
}

.explore-button::before {
  opacity: 30%;
}



@keyframes pulse {
  0% {
    height: 0px;
    top: 38px;
    bottom: unset;
  }

  40% {
    height: 60px;
    top: 38px;
    top: bottom;
  }

  55% {
    height: 60px;
    bottom: -76px;
    top: unset;
  }

  85% {
    height: 0px;
    bottom: -76px;
    top: unset;
  }

  100% {
    height: 0px;
    bottom: -76px;
    top: unset;
  }
}

.swiper-pagination .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  border: 1px solid white;
  opacity: 1;
  background-color: unset;
}


.swiper-pagination-bullet-active {
  background-color: white !important;
}

.swiper .swiper-slide img {
  transition: 0s linear;
}

.swiper-slide-active {
  z-index: 200;
}

.swiper .swiper-slide.transition-ended img {
  transform: scale(1.1);
  transition: 2s ease-in-out;
}


.swiper .swiper-slide.transition-ended .content-wrapper {
  opacity: 1;
}

.swiper .img-container,
.swiper h2 {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.blurred-images-wrapper .overlay {
  transition: 0.5s ease-in-out;
}

.blurred-image {
  transition: 0.5s ease-in-out;
}

.blurred-image.active {
  opacity: 1;
}


.tiles-section .overlay,
.tiles-section h3 {
  transition: 0.3s ease-in-out;
}

.tiles-section img {
  transition: 0.5s ease-out;

}


@media screen and (max-width: 1439px) {

  .tiles-section .tile.active .tile-description,
  .tiles-section .tile.active .overlay,
  .tiles-section .tile.active .plus-icon {
    transition: 0.3s linear;
  }
}



.tiles-section .tile.active .tile-description {
  opacity: 1;
}

.tiles-section .tile.active .overlay {
  opacity: 0.5;
}

.tiles-section .tile.active .plus-icon {
  opacity: 0;
}


@media screen and (max-width:1024px) {
  .hover-image-container {
    display: none;
  }

  .hover-image-wrapper {
    display: none;
  }
}

@media screen and (min-width:769px) {
  .swiper-slide {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (min-width:1440px) {
  .tiles-section .tile .content-container {
    opacity: 0;
    transition: 0.2s linear;
  }

  .tiles-section .tile:hover .content-container {
    opacity: 1;
  }

  .tiles-section .tile:hover .overlay {
    opacity: 0.5;
  }


  .tiles-section img {
    scale: 1.1;
  }

  .tiles-section .tile:hover img {
    transform: scale(1.15);
  }
}


.main-header.nav-open .burger-icon {
  display: none;
}

a:not(.btn-primary, .carousel-controller, .start-reading):hover {
  opacity: 0.5;
}

a:not(.btn-primary, .carousel-controller, .start-reading) {
  transition: opacity 0.2s;
}

.h-fit {
  height: -moz-max-content;
  height: fit-content;
}